<template>
    <template v-if="indicatorActive !== null">
        <o-icon
            v-bind="$attrs"
            :style="{ 'font-size': remSize }"
            :class="[rotating && 'animate-spin']"
        ></o-icon>
        <span
            class="absolute bottom-[0.25rem] right-[0.625rem] block h-2 w-2 rounded-full ring-2 ring-white"
            :class="status"
        />
    </template>
    <o-icon
        v-else
        :style="{ 'font-size': remSize }"
        :class="[rotating && 'animate-spin']"
    ></o-icon>
</template>
<script setup lang="ts">
import type { PropType } from "vue"

const props = defineProps({
    /**
     * For custom sizes, provide a rem value to size the icon by.
     */
    remSize: {
        type: String,
        default: null,
    },
    indicatorActive: {
        type: Boolean as PropType<boolean | null>,
        default: null,
    },
    rotating: {
        type: Boolean,
        default: false,
    },
})

const remSize = computed(() => props.remSize + "rem")

const status = computed(() => {
    if (props.indicatorActive) return `bg-green-400`
    return `bg-gray-400`
})
</script>
